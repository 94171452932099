import { useGetUserQuery, useUpdateUserAccountDataMutation } from '../generated/graphql';
import { useAuthenticationStatus, useUserId } from '@nhost/react';
import { ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import React from 'react'
import { APP_TITLE, PLATFORM_ID, cityList } from 'globalVariables';
import Activate from './auth/Activate';
import { SingleColumnContainer } from './Containers';
import Button from '@mui/material/Button';
import {CustomComboBox} from './Input';
import { roles } from 'atoms';
import { Autocomplete, TextField } from '@mui/material';
import ProfileSettings from './ProfileSettings';

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus();
  const location = useLocation();
  const id = useUserId();
  const getUserQuery = useGetUserQuery({ variables: { id } });
  const user = getUserQuery.data?.user;
  const [isActivated, setIsActivated] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(<ClipLoader/>);

  if (isLoading) {
    return (
      <div className="h-full flex flex-col justify-center items-center p-5 gap-5 w-full text-center">
        Loading dashboard...
        <ClipLoader />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="sign-in" state={{ from: location }} replace />;
  }

  if (!isActivated){
    //make infinite loop
    setTimeout(() => {
      getUserQuery.refetch()
      if (user?.users_user_data?.activated_arup) {
        setIsActivated(true)
      }
    }, 300);
  }

  if (isAuthenticated && !getUserQuery.loading){
    if (user?.emailVerified === false) {
      return <Navigate to="verify" state={{ from: location }} replace />;
    }
    else {
      if (
      (process.env.REACT_APP_USER_ACTIVATION_REQUIRED==="false") ||
      (isActivated)) {
        // if no role is set, redirect to account setup
        if (user?.users_user_data?.role && user?.users_user_data?.address) {
          return <>{children}</>
        }
        else {
          
          return (
            <div className="h-screen pt-24 flex flex-col justify-around">
              <SingleColumnContainer title={"Welcome to "+APP_TITLE} descriptions={["Let's get you set up"]}>
                  
                  <ProfileSettings submitLabel="Continue"/>
              </SingleColumnContainer>
         </div>)
        };
      } else if (!getUserQuery.loading) {
        // if it takes too long to load, show activate screen
        setTimeout(() => {setLoadingScreen(<Activate/>)}, 500);
        return loadingScreen;
        
      }
    }
    
  } 
  
  return (
    <div className="h-full flex flex-col justify-center py-1">
      not loading...
      loading: {getUserQuery.loading?'true':'false'}
      authenticated: {isAuthenticated?'true':'false'}
      <ClipLoader />
    </div>
  );
  // }
};

export default ProtectedRoute;
